<template>
  <div class="registration-page pa-1 pa-sm-3 fill-height">
    <v-form @submit.prevent="submit">
      <v-card class="pa-2">

        <v-card-title>
          Registrierung
        </v-card-title>

        <v-card-text v-if="isDone">
          <v-alert type="success">
            Sie haben sich erfolgreich registriert!<br>
            Bitte öffnen Sie Ihr E-Mail-Postfach und erstellen Sie sich ein individuelles Passwort.
          </v-alert>
        </v-card-text>

        <template v-else>
          <v-card-text class="pt-3">
            <p>
              <v-text-field
                v-model="registration.email"
                label="E-Mail"
                dense
                autofocus
                validate-on-blur
                :rules="[rules.required, rules.email]"
                :readonly="isLoading"
              />
            </p>
            <p>
              <v-select
                dense
                label="Anrede"
                :items="['Herr', 'Frau']"
                v-model="registration.appeal"
              />
            </p>
            <p>
              <v-text-field
                dense
                v-model="registration.firstName"
                label="Vorname"
                validate-on-blur
                :rules="[rules.required]"
                :readonly="isLoading"
              />
            </p>
            <p>
              <v-text-field
                dense
                v-model="registration.lastName"
                label="Nachname"
                validate-on-blur
                :rules="[rules.required]"
                :readonly="isLoading"
              />
            </p>
            <p>
              <v-text-field
                dense
                v-model="registration.organisation"
                label="Schule / Firma"
                validate-on-blur
                :readonly="isLoading"
              />
            </p>
            <p>
              <v-text-field
                dense
                v-model="registration.position"
                label="Position"
                validate-on-blur
                :readonly="isLoading"
              />
            </p>
            <p>
              <v-text-field
                dense
                v-model="registration.postCode"
                label="Postleitzahl"
                validate-on-blur
                :readonly="isLoading"
              />
            </p>
            <p>
              <v-text-field
                dense
                v-model="registration.city"
                label="Stadt"
                validate-on-blur
                :readonly="isLoading"
              />
            </p>
            <p
              v-if="error"
              class="error--text text-center"
              v-text="error"
            />
            <p class="text-center">
              Durch das Einrichten des Klassenbuchmodul Logins akzeptieren Sie unsere
              <a
                href="https://www.ribeka.com/datenschutz/"
                target="_blank"
              >Datenschutzhinweise</a>.
            </p>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              type="submit"
              :loading="isLoading"
            >
              Registrieren
            </v-btn>
          </v-card-actions>
        </template>

      </v-card>
    </v-form>
  </div>
</template>


<script>
import { required, email } from '@/helpers/validators'
import { isEmailValid } from '@/helpers/email'
import { api, getErrorReason } from '@/api'

export default {
  name: 'RegistrationPage',
  data: () => ({
    registration: {
      email: '',
      appeal: '',
      firstName: '',
      lastName: '',
      postCode: '',
      city: '',
      organisation: '',
      position: '',
    },
    rules: {required, email},
    isLoading: false,
    error: null,
    isDone: false
  }),
  computed: {
    canSubmit() {
      const { email, firstName, lastName } = this.registration
      return isEmailValid(email) && !!firstName && !!lastName
    },
  },
  methods: {
    async submit() {
      if (!this.canSubmit) return
      try {
        this.error = null
        this.isLoading = true
        await api.post('/api/registrations', this.registration)
        this.isDone = true
      } catch (e) {
        this.handleError(e)
      } finally {
        this.isLoading = false
      }
    },
    handleError(e) {
      const violations = e?.response?.data?.violations || [{}]
      this.error = violations[0].message || getErrorReason(e) || 'Fehler!'
    }
  }
}
</script>


<style lang="scss" scoped>
.registration-page {
  max-width: 600px;
  margin: 0 auto;
  overflow: auto;
}
</style>
